


































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/PickupRadiusValidationController'
import { PickupRadiusValidation, Summary } from '@/domain/entities/PickupRadiusValidation'
import {
  IHeaderCell,
  IOptions,
  IPickupRadiusValidationListParameters,
  IPickupRadiusValidationListParams,
  ITableCell,
  IValidationStatusMapper,
} from '@/data/infrastructures/misc/interfaces/pickupRadiusValidation'
import {
  pickupStatus,
  SortFields,
  SortType,
} from '@/app/infrastructures/misc/Constants/pickupRadiusValidation'
import DropdownMultiSelect from '@/app/ui/views/Driver/components/DropdownMultiSelect/index.vue'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DateTimePickerV2,
    DataTableV2,
    PaginationNav,
    Badge,
    AscendingIcon,
    LoadingOverlay,
    DropdownMultiSelect,
  },
})
export default class PickupRadiusValidationPage extends Vue {
  controller = controller
  routeController = routeController

  enumSelectedSort = SortType
  enumSortFields = SortFields
  enumPickupStatus = pickupStatus

  selectedSort: SortType | null = null
  tableData: ITableCell[][] = []
  summaryData: Summary = {
    fraud: 0,
    needValidation: 0,
    valid: 0
  }

  statusOptions: IOptions[] = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Valid',
      value: '1',
    },
    {
      label: 'Fiktif',
      value: '2',
    },
    {
      label: 'Need Validation',
      value: '0',
    },
  ]

  courierTypeOptions: IOptions[] = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'KVP',
      value: 'KVP',
    },
    {
      label: 'POS',
      value: 'POS',
    },
  ]

  originDataOptions: IOptions[] = []

  datepickerPresets = [
    {
      key: 'last31days',
      label: '31 Hari Terakhir',
      value: 31,
    },
  ]

  parameters: IPickupRadiusValidationListParameters = {
    page: 1,
    perPage: 50,
    search: '',
    status: this.statusOptions[0],
    courierType: this.courierTypeOptions[0],
    districtCode: [],
    dateRange: {
      start: new Date(),
      end: new Date(),
    },
  }

  headers: IHeaderCell[] = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Shipment ID', '140px'),
    this.headerCellMapper('Nama Kurir', '200px'),
    this.headerCellMapper('Nomor HP', '170px'),
    this.headerCellMapper('Jenis Kurir', '114px'),
    this.headerCellMapper('3LC', '80px'),
    this.headerCellMapper('Waktu Pick Up', '185px'),
    this.headerCellMapper('Status', '170px', 'center'),
    this.headerCellMapper('Aksi', '100px'),
  ]

  created(): void {
    this.fetchPickupRadiusValidationData(true)
    this.routeController.getLetterCodes()
  }

  get params(): IPickupRadiusValidationListParams {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      status: this.parameters.status.value,
      startDate: this.parameters.dateRange.start
        ? Utils.formatDateWithIDLocale(
            this.parameters.dateRange.start.toISOString(),
            'YYYY-MM-DD'
          )
        : this.todayFormatedDate,
      endDate: this.parameters.dateRange.end
        ? Utils.formatDateWithIDLocale(
            this.parameters.dateRange.end.toISOString(),
            'YYYY-MM-DD'
          )
        : this.todayFormatedDate,
      column: this.selectedSort ? this.selectedSort.split('-')[0] : '',
      sortDirection: this.selectedSort ? this.selectedSort.split('-')[1] : '',
      courierType: this.parameters.courierType.value,
      origin: this.parameters.districtCode.join(','),
    }
  }

  get todayFormatedDate(): string {
    return Utils.formatDateWithIDLocale(new Date().toISOString(), 'YYYY-MM-DD')
  }

  get disableExportButton(): boolean {
    return (
      this.controller.paginationData.totalItem === 0 ||
      this.controller.isLoadingExport
    )
  }

  private fetchPickupRadiusValidationData(reset?: boolean): void {
    if (reset) {
      this.parameters.page = 1
    }

    if (!this.parameters.status.value) {
      delete this.params.status
    }

    if (!this.selectedSort) {
      delete this.params.column
      delete this.params.sortDirection
    }

    controller.getPickupRadiusValidations(this.params)
  }

  private onChangeKeyword = Utils.debounce((keyword: string): void => {
    if (keyword.length === 0 || keyword.length >= 3) {
      this.fetchPickupRadiusValidationData(true)
    }
  }, 400)

  private onChangeStatus(value: IOptions): void {
    if (value === null) {
      this.parameters.status = this.statusOptions[0]
    }
    this.fetchPickupRadiusValidationData(true)
  }

  private onChangeCourierType(value: IOptions): void {
    if (value === null) {
      this.parameters.courierType = this.courierTypeOptions[0]
    }
    this.fetchPickupRadiusValidationData(true)
  }

  private onChangeDate(): void {
    this.fetchPickupRadiusValidationData(true)
  }

  private onChangeDistrictCode(value: string[]): void {
    this.parameters.districtCode = value
    this.fetchPickupRadiusValidationData(true)
  }

  private onTableSort(sortType: SortFields): void {
    switch (sortType) {
      case SortFields.COURIER_NAME:
        if (this.selectedSort === SortType.COURIER_NAME_ASC) {
          this.selectedSort = SortType.COURIER_NAME_DESC
        } else if (this.selectedSort === SortType.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.COURIER_NAME_ASC
        }
        break
      case SortFields.COURIER_PHONE_NUMBER:
        if (this.selectedSort === SortType.COURIER_PHONE_NUMBER_ASC) {
          this.selectedSort = SortType.COURIER_PHONE_NUMBER_DESC
        } else if (this.selectedSort === SortType.COURIER_PHONE_NUMBER_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.COURIER_PHONE_NUMBER_ASC
        }
        break
      case SortFields.CREATED_AT:
        if (this.selectedSort === SortType.CREATED_AT_ASC) {
          this.selectedSort = SortType.CREATED_AT_DESC
        } else if (this.selectedSort === SortType.CREATED_AT_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.CREATED_AT_ASC
        }
        break
    }
    this.fetchPickupRadiusValidationData(true)
  }

  private handleExportPickupLog(): void {
    const params = {
      ...this.params,
      timeZone: encodeURIComponent(
        Utils.formatDateWithIDLocale(new Date().toISOString(), 'Z')
      ),
    }

    delete params.column
    delete params.sortDirection
    delete params.page
    delete params.perPage

    controller.exportPickupLog(params)
  }

  private validationStatusMapper(status: string): IValidationStatusMapper {
    switch (status) {
      case this.enumPickupStatus.VALID:
        return {
          text: 'Valid',
          color: 'success',
        }
      case this.enumPickupStatus.NEED_VALIDATION:
        return {
          text: 'Need<br>Validation',
          color: 'warning',
        }
      default:
        return {
          text: 'Fiktif',
          color: 'error-1',
        }
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    textAlign?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        textAlign: textAlign,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    justifyContent?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        display: 'flex',
        justifyContent: justifyContent || '',
      },
    }
  }

  @Watch('controller.pickupRadiusValidationData')
  private onPickupRadiusValidationDataChange(
    data: PickupRadiusValidation[]
  ): void {
    this.tableData = data.map((item, index) => [
      this.tableCellMapper(
        index + 1 + this.parameters.perPage * (this.parameters.page - 1) + '.',
        '60px'
      ),
      this.tableCellMapper(item.shipmentId || '-', '140px'),
      this.tableCellMapper(
        `[${item.courierId}] ${item.courierName} </br> (${item.courierTypeValid}) ${item.courierPhoneNumber}` ||
          '-',
        '200px'
      ),
      this.tableCellMapper(item.courierPhoneNumber || '-', '170px'),
      this.tableCellMapper(item.courierTypeValid || '-', '114px'),
      this.tableCellMapper(item.courierLetterCode || '-', '80px'),
      this.tableCellMapper(
        item.createdAt
          ? Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(
                item.createdAt,
                'DD MMM YYYY HH:mm Z'
              )
            )
          : '-',
        '185px'
      ),
      this.tableCellMapper(item.statusPickup || '-', '170px', 'center'),
      this.tableCellMapper(item.id || '-', '100px'),
    ])
  }

  @Watch('controller.pickupRadiusValidationSummaryData')
  private setSummaryData(data: Summary): void {
    this.summaryData = data
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.originDataOptions = data.map((route) => {
      return {
        label: <string>route.lc,
        value: <string>route.lc
      }
    })
  }
}
